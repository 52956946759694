import React from 'react';

const IconKeybase = ({ classes }) => (


<svg className={classes} x="0px" y="0px"
	 viewBox="0 0 612 612">

<rect fill="#5D5D5D" width="480" height="480"/>
<g>
	<path fill="#FE874A" d="M273.938,369.372c46.09,0.704,54.361-45.843,60.449-53.7c6.112,13.477,32.805,56.165,64.7,54.966
		c50.652-1.903,70.028-51.041,34.967-84.971c-20.255-19.602-45.271-25.543-56.889-9.312c-14.813,20.695,36.402,11.452,44.569,38.276
		c5.183,17.022-10.349,29.473-24.462,28.358c-16.605-1.312-38.748-44.91-42.866-55.87c-8.533-22.713-4.363-34.698-0.561-57.147
		c6.159-36.363,12.683-56.025,14.95-79.105c1.572-16.009,4.503-19.516-13.757-32.383c-14.958-10.54-22.525,16.342-27.561,32.362
		c-10.91,34.707-14.324,80.865-24.245,129.072c-7.65,51.839-42.25,58.323-56.205,37.279c-12.95-19.526-2.929-38.332-11.033-55.384
		c-10.618-22.342-25.935-33.273-50.527-30.579c-5.309,0.581-10.585,1.46-15.876,2.202l-0.616,0.822c0,0,0.756-0.678,0.755-0.679
		c1.537-1.845,2.929-3.836,4.634-5.511c25.405-24.956,49.93-44.932,78.81-65.468c18.462-13.128,34.829-24.187,24.929-40.47
		c-7.473-12.292-26.982-10.21-45.964,3.079c-40.73,28.514-61.926,52.922-89.784,82.738c-2.704,2.894-8.169,10.704-9.83,7.724
		c0.221-21.523,1.392-42.804,0.443-63.992c-1.567-34.985-24.749-50.105-58.088-38.479c-11.43,3.986-22.31,9.539-33.431,14.4
		c-11.548,5.048-13.374,14.261-11.996,25.668c1.62,13.406,12.346,17.599,25.373,16.101c7.742-0.89,13.467-5.406,19.26-9.685
		c10.07-7.439,14.905-3.499,16.929,7.14c1.902,9.995,2.792,20.056,2.863,30.285c0.345,49.953-1.673,92.065-5.68,141.728
		c-1.285,15.919-2.073,26.898,16.751,28.797c17.873,1.804,26.015-5.024,26.331-20.961c0.336-16.942,0.266-33.895,0.136-50.842
		c-0.075-9.83,4.48-16.149,13.07-20.759c26.963-14.469,47.259-6.348,55.455,23.171C205.243,317.343,195.382,366.12,273.938,369.372z
		"/>
</g>	
</svg>



);


export default IconKeybase;
